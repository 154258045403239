@tailwind base;

/* purgecss start ignore */
:root {
  --primary: theme("colors.gray.100");
  --primary-hover: theme("colors.gray.200");
  --primary-active: theme("colors.gray.300");
  --sat: env(safe-area-inset-top);
  --sar: env(safe-area-inset-right);
  --sab: env(safe-area-inset-bottom);
  --sal: env(safe-area-inset-left);
}

@keyframes fadeInAnimation {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOutAnimation {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes slideInAnimation {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes slideOutAnimation {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(100%);
  }
}

@keyframes slideUpInAnimation {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes slideUpOutAnimation {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(100%);
  }
}

@keyframes scaleInAnimation {
  from {
    transform: scale(0.8);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes scaleOutAnimation {
  from {
    transform: scale(1);
    opacity: 1;
  }
  to {
    transform: scale(0.8);
    opacity: 0;
  }
}

@keyframes springInAnimation {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes toastInAnimation {
  from {
    opacity: 0;
    transform: translateY(-120%);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes toastOutAnimation {
  from {
    transform: scale(1);
    opacity: 1;
  }
  to {
    transform: scale(0.9);
    opacity: 0;
  }
}

html {
  font-size: 15px;
  overscroll-behavior: none;
}
/* @media (min-width: 321px) and ((-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi)) {
  html {
    font-size: 16px;
  }
} */

body,
html,
#root {
  @apply relative h-full w-full overflow-hidden;
}

html,
body {
  @apply h-screen;
}

body {
  @apply font-sans m-0 p-0 w-full;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
}

#main-view {
  @apply relative h-full w-full top-0 left-0 z-10 overflow-hidden box-border;
}
#main-view #main-view-inner {
  @apply absolute w-full top-0 left-0 z-10 overflow-hidden box-border;
}
#main-view #main-view-inner .content {
  @apply relative h-full z-10 overflow-auto box-border;
  -webkit-overflow-scrolling: touch;
}

.h-minus-nav {
  height: calc(100vh - 6rem - 2px - env(safe-area-inset-bottom, 0));
}

.bottom-minus-nav {
  bottom: calc(6rem + 2px - env(safe-area-inset-bottom, 0));
}

.overlay::before {
  @apply bg-gray-900 bg-opacity-25 absolute top-0 left-0 w-full h-full;
  content: " ";
}
.overlay-enter::before,
.overlay-enter-done::before,
.overlay-appear::before,
.overlay-appear-done::before {
  animation: fadeInAnimation 240ms cubic-bezier(0, 0, 0.2, 1) both;
}
.overlay-exit::before,
.overlay-exit-done::before {
  animation: fadeOutAnimation 240ms cubic-bezier(0.4, 0, 1, 1) both;
}

.dialog {
  @apply fixed top-auto left-0 right-0 ml-2 mr-2;
  width: calc(100% - 1rem);
  bottom: env(safe-area-inset-bottom, 0.5rem);
  max-height: calc(
    100% - env(safe-area-inset-bottom, 0.5rem) -
      env(safe-area-inset-bottom, 0.5rem)
  );
}

.dialog-tall {
  top: env(safe-area-inset-bottom, 0.5rem);
}
@supports (padding: max(0px)) {
  .dialog {
    bottom: max(0.5rem, env(safe-area-inset-bottom));
    max-height: calc(
      100% - max(0.5rem, env(safe-area-inset-bottom)) -
        max(0.5rem, env(safe-area-inset-top))
    );
  }
  .dialog-tall {
    top: max(0.5rem, env(safe-area-inset-top));
  }
}
.dialog-enter,
.dialog-enter-done,
.dialog-appear,
.dialog-appear-done {
  animation: slideUpInAnimation 200ms cubic-bezier(0, 0, 0.2, 1) both;
}
.dialog-exit,
.dialog-exit-done {
  animation: slideUpOutAnimation 200ms cubic-bezier(0.4, 0, 1, 1) both;
}

@media (min-width: 768px) {
  .dialog {
    @apply w-140 static inset-auto self-center;
  }
  .dialog-xwide {
    @apply w-180;
  }
  .dialog-wide {
    @apply w-154;
  }
  .dialog-narrow {
    @apply w-100;
  }
  .dialog-enter,
  .dialog-enter-done,
  .dialog-appear,
  .dialog-appear-done {
    animation: scaleInAnimation 200ms cubic-bezier(0, 0, 0.2, 1) both;
  }
  .dialog-exit,
  .dialog-exit-done {
    animation: scaleOutAnimation 200ms cubic-bezier(0.4, 0, 1, 1) both;
  }
}

.side-sheet-right {
  @apply fixed h-full right-0 left-auto;
  /* min-width: 34rem; */
  max-width: calc(100% - 6rem - env(safe-area-inset-left));
}
.side-sheet-right-enter,
.side-sheet-right-enter-done,
.side-sheet-right-appear,
.side-sheet-right-appear-done {
  animation: slideInAnimation 200ms cubic-bezier(0, 0, 0.2, 1) both;
}
.side-sheet-right-exit,
.side-sheet-right-exit-done {
  animation: slideOutAnimation 200ms cubic-bezier(0.4, 0, 1, 1) both;
}

.side-sheet-bottom {
  @apply fixed top-auto;
  bottom: env(safe-area-inset-bottom, 0);
  left: 0;
  right: 0;
  max-height: calc(
    100% - env(safe-area-inset-top) - env(safe-area-inset-bottom, 0)
  );
}
.side-sheet-bottom-enter,
.side-sheet-bottom-enter-done,
.side-sheet-bottom-appear,
.side-sheet-bottom-appear-done {
  animation: slideUpInAnimation 200ms cubic-bezier(0, 0, 0.2, 1) both;
}
.side-sheet-bottom-exit,
.side-sheet-bottom-exit-done {
  animation: slideUpOutAnimation 200ms cubic-bezier(0.4, 0, 1, 1) both;
}

.side-sheet.side-sheet-fullscreen {
  height: calc(100% - env(safe-area-inset-top) - env(safe-area-inset-bottom));
}

.toast-manager {
  @apply fixed right-0 left-0 z-100 pointer-events-none mx-4 my-2;
  top: env(safe-area-inset-top, 0);
}

.toast {
  @apply flex flex-col items-center mb-2;
}
.toast-enter,
.toast-enter-done,
.toast-appear,
.toast-appear-done {
  animation: toastInAnimation 240ms cubic-bezier(0, 0, 0.2, 1) both;
}
.toast-exit,
.toast-exit-done,
.toast-exit-active {
  animation: toastOutAnimation 120ms cubic-bezier(0.4, 0, 1, 1) both;
}

.safe-pt {
  padding-top: env(safe-area-inset-top);
}

.safe-pr {
  padding-right: env(safe-area-inset-right);
}

.safe-pb {
  padding-bottom: env(safe-area-inset-bottom);
}

.safe-pl {
  padding-left: env(safe-area-inset-left);
}

.safe-pt-2 {
  padding-top: 0.5rem;
}
.safe-pt-4 {
  padding-top: 1rem;
}
.safe-pr-2 {
  padding-right: 0.5rem;
}
.safe-pr-4 {
  padding-right: 1rem;
}
.safe-pb-2 {
  padding-bottom: 0.5rem;
}
.safe-pb-3 {
  padding-bottom: 0.5rem;
}
.safe-pb-4 {
  padding-bottom: 1rem;
}
.safe-pb-8 {
  padding-bottom: 2rem;
}
.safe-pl-4 {
  padding-left: 1rem;
}
.safe-pl-2 {
  padding-left: 0.5rem;
}

@supports (padding: max(0px)) {
  .safe-pt-2 {
    padding-top: max(0.5rem, env(safe-area-inset-top));
  }
  .safe-pt-4 {
    padding-top: max(1rem, env(safe-area-inset-top));
  }
  .safe-pr-2 {
    padding-right: max(0.5rem, env(safe-area-inset-right));
  }
  .safe-pr-4 {
    padding-right: max(1rem, env(safe-area-inset-right));
  }
  .safe-pb-2 {
    padding-bottom: max(0.5rem, env(safe-area-inset-bottom));
  }
  .safe-pr-3 {
    padding-bottom: max(0.75rem, env(safe-area-inset-right));
  }
  .safe-pb-4 {
    padding-bottom: max(1rem, env(safe-area-inset-bottom));
  }
  .safe-pb-8 {
    padding-bottom: max(2rem, env(safe-area-inset-bottom));
  }
  .safe-pl-2 {
    padding-left: max(0.5rem, env(safe-area-inset-left));
  }
  .safe-pl-4 {
    padding-left: max(1rem, env(safe-area-inset-left));
  }
}
/* purgecss end ignore */

@import "~swiper/swiper-bundle.css";

.swiper-container {
  width: calc(100vw - 1.5rem - 1.5rem);
}

@media (min-width: 1024px) {
  .swiper-container {
    width: calc(100vw - 16rem - 1.5rem - 1.5rem);
  }
}
.swiper-slide {
  @apply w-full pb-10;
}

.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-container-horizontal > .swiper-pagination-bullets {
  @apply border-t border-gray-200 h-10 left-0 right-0 bottom-0 top-auto;
}
.swiper-pagination-bullet {
  @apply bg-gray-600;
}
.swiper-pagination-bullet-active {
  @apply bg-black;
}

.grabbable {
  cursor: move; /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

/* (Optional) Apply a "closed-hand" cursor during drag operation. */
.grabbable:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}

.yard-bay:after {
  content: "";
  @apply block absolute z-10 pointer-events-none inset-0;
}
.yard-bay:hover:after {
  @apply border-2 border-gray-300 bg-gray-600 bg-opacity-10;
}

.yard-bay.load_requested:after {
  @apply border border-amber-400 bg-amber-400 bg-opacity-10;
}
.yard-bay.load_requested:hover:after {
  @apply border-2 border-amber-400 bg-amber-400 bg-opacity-30;
}

.yard-bay.clear_requested:after {
  @apply border border-rose-400 bg-rose-400 bg-opacity-10;
}
.yard-bay.clear_requested:hover:after {
  @apply border-2 border-rose-400 bg-rose-400 bg-opacity-30;
}

@tailwind components;
@tailwind utilities;
